import React from 'react'
import Link from 'gatsby-link'
import { customerNav } from 'components/Sidebar/navigationLinks'
import CustomerLayout from 'layout/CustomerLayout'
import "components/layout.scss"

const links = (section, i) => {
  return customerNav[i][Object.keys(section)].map((item, i)=>{

    if(item.link !== ""){
      return (
        <li key={i}><Link to={item.link}>{item.text}</Link></li>
      )
    } else {
      return (
        <li key={i} className="subheading"><b>{item.text}</b></li>
      )
    }
    
  })
}

const Menu = () => {
  return customerNav.map((item, i)=>{
    const section = Object.keys(item)
    return (
      <div key={i}>
        <header>{section}</header>
        <ul className="list-style-none">
          {links(customerNav[i], i)}
        </ul>
      </div>
    )
  })
}

const SitemapPage = () => (
  <CustomerLayout>

    <h1>Customer Sitemap</h1>
    <Menu />
  </CustomerLayout>
)
export default SitemapPage